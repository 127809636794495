import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import Reload from 'components/elements/Reload';
import { fetchSalesInvoice, creditSalesInvoice, approveSalesInvoice } from 'actions/salesinvoice';
import Response from 'services/Response';
import NumberFormatting from 'services/NumberFormatting';
import { Button, ButtonToolbar, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import PromptDialog from 'components/elements/PromptDialog';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import ItemCount from 'components/elements/ItemCount';
import { fetchPurchaseInvoices } from 'actions/purchaseinvoice';
import Paginator from 'components/elements/Paginator';
import { states } from 'constants/salesinvoice';
import { DateTime } from 'luxon';

const mapStateToProps = (state, ownProps) => {
    return {
        isRequesting: state.salesInvoice.isRequesting || state.purchaseInvoice.isRequesting,
        salesInvoiceResponse: state.salesInvoice.salesInvoices[ownProps.match.params.id],
        purchaseInvoiceCollectionResponse: state.purchaseInvoice.collection,
    };
};

const mapDispatchToProps = {
    fetchSalesInvoice: fetchSalesInvoice,
    creditSalesInvoice: creditSalesInvoice,
    approveSalesInvoice: approveSalesInvoice,
    fetchPurchaseInvoices: fetchPurchaseInvoices,
};

class SalesInvoiceDetails extends Component
{
    constructor(props) {
        super(props);

        this.load = this.load.bind(this);

        this.state = {
            showRaiseCreditPrompt: false,
            showApprovalConfirmation: false,
            salesCreditNumber: '',
            salesCreditNote: '',
            purchaseInvoicePage: 1,
        };
    }

    render() {
        const salesInvoice = this.props.salesInvoiceResponse || {};

        const purchaseInvoiceColumns = [
            {header: 'Exchequer PIN', width: 1, visible: true, key: 'exchequerPurchaseInvoiceNumber', },
            {header: 'Elogbooks Job ID', width: 1, visible: true, key: '_links.job.elogbooksJobId', },
            {header: 'Service Provider', width: 1, visible: true, key: '_links.serviceProvider.name', },
            {header: 'Total Labour', type: 'currency', width: 1, visible: true, key: 'labourValueApproved', },
            {header: 'Total Material', type: 'currency', width: 1, visible: true, key: 'materialValueApproved', },
            {header: 'Total', width: 1, type: 'currency', visible: true, key: 'totalValueApproved', },
        ];

        const purchaseInvoiceCollection = this.props.purchaseInvoiceCollectionResponse || {};

        return (
            <div className="SalesInvoiceDetails">
                <Loading isLoading={this.props.isRequesting} size="md">
                    <h1 className="clearfix">
                        {salesInvoice.exchequerSalesInvoiceNumber || 'Pending Sync'}

                        <Reload load={this.load} />

                        <ButtonToolbar className="float-right">
                            { Response.getLink(salesInvoice, 'credit') === null ? null :
                                <Button onClick={() => this.setState({showRaiseCreditPrompt: true})}
                                    size="sm">
                                    Raise Credit
                                </Button>
                            }
                            { Response.getLink(salesInvoice, 'approve') === null ? null :
                                <Button onClick={() => this.setState({showApprovalConfirmation: true})}
                                    size="sm"
                                    className="ml-2">
                                    Approve
                                </Button>
                            }
                        </ButtonToolbar>

                        <PromptDialog show={this.state.showRaiseCreditPrompt}
                            hide={() => this.setState({showRaiseCreditPrompt: false})}
                            onSubmit={(e) => this.handleRaiseCredit(e)}
                            validateForm={() => {return this.validateSalesCreditForm()}}
                            title={`Credit ${salesInvoice.exchequerSalesInvoiceNumber}`}>
                            <FormGroup controlId="creditNumber">
                                <FormLabel>Sales Credit Number</FormLabel>
                                <FormControl as="input"
                                    ref="creditNumber"
                                    value={this.state.salesCreditNumber}
                                    onChange={e => { this.setState({salesCreditNumber: e.target.value}) }} />
                            </FormGroup>
                            <FormGroup controlId="note">
                                <FormLabel>Note</FormLabel>
                                <FormControl as="textarea"
                                    rows="4"
                                    maxLength="3000"
                                    value={this.state.salesCreditNote}
                                    onChange={e => {this.setState({'salesCreditNote': e.target.value})}} />
                            </FormGroup>
                        </PromptDialog>

                        <ConfirmationDialog show={this.state.showApprovalConfirmation}
                            hide={() => this.setState({showApprovalConfirmation: false})}
                            onConfirm={this.handleApprove} />
                    </h1>

                    <dl>
                        <dt>Client</dt>
                        <dd>{Response.getLinkAttribute(salesInvoice, 'client', 'title')}</dd>
                        <dt>Property</dt>
                        <dd>{Response.getLinkAttribute(salesInvoice, 'property', 'title')}</dd>
                        <dt>Sage Purchase Order Number</dt>
                        <dd>{salesInvoice.sagePurchaseOrderNumber}</dd>
                        <dt>Exchequer(Sage) Sales Invoice Number</dt>
                        <dd>{salesInvoice.exchequerSalesInvoiceNumber}</dd>
                        <dt>Total Labour</dt>
                        <dd>{NumberFormatting.formatCurrency(salesInvoice.labourValue)}</dd>
                        <dt>Total Materials</dt>
                        <dd>{NumberFormatting.formatCurrency(salesInvoice.materialValue)}</dd>
                        <dt>Total (Labour + Materials)</dt>
                        <dd>{NumberFormatting.formatCurrency(salesInvoice.labourValue + salesInvoice.materialValue)}</dd>
                        <dt>State</dt>
                        <dd>{states[salesInvoice.state]}</dd>
                        { !salesInvoice.transactionDate ? null : (
                            <>
                                <dt>Transaction Date</dt>
                                <dd>{DateTime.fromISO(salesInvoice.transactionDate).toLocaleString()}</dd>
                            </>
                        )}
                    </dl>

                    <div className="mt-3 clearfix">
                        <h2>
                            Purchase Invoices
                            <ItemCount count={purchaseInvoiceCollection.count} />

                            <Reload load={() => {this.loadPurchaseInvoices()}} />
                        </h2>

                        <NoResultsFound count={purchaseInvoiceCollection.count}>
                            <Table data={purchaseInvoiceCollection.purchaseInvoices || []}
                                columns={purchaseInvoiceColumns}
                                collection={purchaseInvoiceCollection} />

                            <Paginator page={purchaseInvoiceCollection.page}
                                count={purchaseInvoiceCollection.count}
                                limit={purchaseInvoiceCollection.limit}
                                onPage={(page) => this.changePurchaseInvoicePage(page)} />
                        </NoResultsFound>
                    </div>
                </Loading>
            </div>
        );
    }

    componentDidMount() {
        this.load().then((loaded) => {
            if (loaded) {
                this.loadPurchaseInvoices();
            }
        });
    }

    handleRaiseCredit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({showRaiseCreditPrompt: false});

        this.props.creditSalesInvoice(
            this.props.salesInvoiceResponse.id,
            {
                salesCreditNumber: this.state.salesCreditNumber,
                note: this.state.salesCreditNote,
            }
        ).then((result) => {
            if (result) {
                this.setState({salesCreditNumber: '', salesCreditNote: ''});
            }
        });
    }

    handleApprove = () => {
        this.setState({showApprovalConfirmation: false});

        this.props.approveSalesInvoice(this.props.salesInvoiceResponse.id);
    };

    validateSalesCreditForm() {
        return this.state.salesCreditNumber && this.state.salesCreditNote;
    }

    load() {
        const { id } = this.props.match.params;

        return this.props.fetchSalesInvoice(id);
    }

    loadPurchaseInvoices() {
        return this.props.fetchPurchaseInvoices({
            datagroup: 'details',
            page: this.state.purchaseInvoicePage,
            salesInvoice: this.props.salesInvoiceResponse.id,
        });
    }

    changePurchaseInvoicePage(page) {
        this.setState({purchaseInvoicePage: page}, () => {
            this.loadPurchaseInvoices();
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceDetails);
