import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, FormGroup, FormControl, FormLabel, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchAllClients } from 'actions/dashboard';
import { create, update, fetchServiceProvider } from 'actions/serviceprovider';
import { find, remove } from 'lodash';

const mapStateToProps = (state, ownProps) => {
    return {
        clients: {
            collection: state.dashboard.clients.collection,
            filters: state.dashboard.clients.filters,
            isLoading: state.dashboard.clients.isRequesting,
        },
        serviceProviderResponse: state.serviceProvider.serviceproviders[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    create: create,
    update: update,
    fetchAllClients: fetchAllClients,
    fetchServiceProvider: fetchServiceProvider,
};

class ServiceProviderAddEdit extends Component
{
    constructor(props) {
        super(props);

        const serviceProvider = this.props.serviceProviderResponse || {};

        this.state = {
            name: serviceProvider.name || "",
            exchequerEmployeeId: serviceProvider.exchequerEmployeeId || "",
            sageSupplierReference: serviceProvider.sageSupplierReference || "",
            isActive: [true, false].indexOf(serviceProvider.isActive) !== -1 ? (serviceProvider.isActive ? 'true' : 'false') : 'false',
            partnerships: serviceProvider.partnerships || [],
            // isActive: serviceProvider.isActive || "false",
        };
    }

    validateForm() {
        let valid = (
            this.state.name &&
            this.state.exchequerEmployeeId &&
            [true, false, "true", "false"].indexOf(this.state.isActive) !== -1 &&

            this.refs.name &&
            this.refs.exchequerEmployeeId &&

            this.refs.name.validity.valid &&
            this.refs.exchequerEmployeeId.validity.valid
        );

        return valid;
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const data ={
            name: this.state.name,
            elogbooksUserId: this.state.elogbooksUserId,
            exchequerEmployeeId: this.state.exchequerEmployeeId,
            sageSupplierReference: this.state.sageSupplierReference,
            isActive: this.state.isActive,
            partnerships: this.state.partnerships,
        };

        if (this.props.serviceProviderResponse &&
            this.props.serviceProviderResponse.id) {
            return this.props
                .update(this.props.serviceProviderResponse.id, data)
                .then((response) => {
                    this.props.history.push('/serviceproviders/' + this.props.serviceProviderResponse.id);
                }, (response) => {
                    // @todo show an error
                });
        }

        return this.props
            .create(data)
            .then((response) => {
                this.props.history.push('/serviceproviders');
            }, (response) => {
                // @todo show an error
            });
    }

    render() {
        const { match, history } = this.props;
        const { id } = this.props.match.params;
        const cancelState = match.url.substring(0, match.url.lastIndexOf('/'));

        return (
            <div className="ServiceProviderAddEdit">
                <h1 className="clearfix">
                    { id ? 'Edit Service Provider' : 'Add a New Service Provider' }
                </h1>

                <form ref="form"
                    className="clearfix"
                    onSubmit={e => this.handleSubmit(e)}>
                    <FormGroup controlId="name" bssize="large">
                        <FormLabel>Name</FormLabel>
                        <FormControl as="input"
                            ref="name"
                            value={this.state.name}
                            onChange={e => { this.setState({"name": e.target.value}) }}
                            autoFocus />
                    </FormGroup>
                    <FormGroup controlId="exchequerEmployeeId" bssize="large">
                        <FormLabel>Exchequer Employee ID</FormLabel>
                        <FormControl as="input"
                            type="number"
                            min="1"
                            ref="exchequerEmployeeId"
                            value={this.state.exchequerEmployeeId}
                            onChange={e => { this.setState({"exchequerEmployeeId": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="sageSupplierReference" bssize="large">
                        <FormLabel>Sage Supplier Reference</FormLabel>
                        <FormControl as="input"
                                     type="text"
                                     ref="sageSupplierReference"
                                     value={this.state.sageSupplierReference}
                                     onChange={e => { this.setState({"sageSupplierReference": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="isActive" bssize="large">
                        <FormLabel>Status</FormLabel>
                        <FormControl as="select"
                            ref="isActive"
                            value={this.state.isActive}
                            onChange={e => { this.setState({"isActive": e.target.value}) }}>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                        </FormControl>
                    </FormGroup>

                    { this.listPartnerships() }

                    <div className="float-right">
                        <Button onClick={() => history.push(cancelState)}
                            variant="default"
                            bssize="large"
                            to={cancelState}>
                            <FontAwesomeIcon icon={['fas', 'ban']} />
                            Cancel
                        </Button>

                        <Button type="submit"
                            className="margin-left"
                            bssize="large"
                            disabled={!this.validateForm()}>
                            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                            { id ? 'Update' : 'Create' }
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    listPartnerships()
    {
        const clients = this.props.clients.collection.clients.slice();
        const { partnerships } = this.state;

        let rows = [];
        while (clients.length >= 1) {
            rows.push(clients.splice(0, 3));
        }

        return (
            <>
                <h2>
                    Partnerships
                </h2>
                <Container fluid className="no-padding">
                    { rows.map((row, index) => {
                        return (
                            <Row sm={3} md={3} lg={3} xl={3} noGutters={true} key={index}>
                                { row.map((item) => {
                                    let predicate = {_links: {client: {href: item._links.self.href}}};
                                    let partnership = find(this.state.partnerships, predicate);

                                    return (
                                        <Col sm={4} className="bordered" key={item.id}>
                                            <div className="content">
                                                <h3>{ item.name }</h3>
                                                <FormGroup controlId={"partnership-" + item.id} bssize="large">
                                                    <FormLabel>Elogbooks User ID</FormLabel>
                                                    <FormControl as="input"
                                                        type="number"
                                                        min={0}
                                                        ref={"elogbooksUserId-" + item.id}
                                                        value={typeof partnership === 'undefined' ? '' : partnership.elogbooksUserId}
                                                        onChange={e => {
                                                            remove(partnerships, predicate);
                                                            partnerships.push(
                                                                Object.assign(
                                                                    {},
                                                                    partnership || {},
                                                                    {
                                                                        elogbooksUserId: e.target.value,
                                                                        _links: {
                                                                            client: {
                                                                                href: item._links.self.href,
                                                                            },
                                                                        },
                                                                    }
                                                                )
                                                            );

                                                            this.setState({partnerships: partnerships});
                                                        }} />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    );
                                }) }
                            </Row>
                        );
                    }) }
                </Container>
            </>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderAddEdit);
