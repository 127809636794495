import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Col, Container, Row } from 'react-bootstrap';
import { fetchAllClients } from 'actions/dashboard';
import { fetchServiceProvider, hold, cancelHold } from 'actions/serviceprovider';
import Loading from 'components/higher-order-components/Loading';
import Reload from 'components/elements/Reload';
import JWT from 'services/JWT';
import { find } from 'lodash';
import Response from 'services/Response';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';

const mapStateToProps = (state, ownProps) => {
    return {
        clients: {
            collection: state.dashboard.clients.collection,
            filters: state.dashboard.clients.filters,
            isLoading: state.dashboard.clients.isRequesting,
        },
        isFetching: state.serviceProvider.isFetching,
        serviceProviderResponse: state.serviceProvider.serviceproviders[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    fetchAllClients: fetchAllClients,
    fetchServiceProvider: fetchServiceProvider,
    hold: hold,
    cancelHold: cancelHold,
};

class ServiceProviderDetails extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            showHoldConfirmation: false,
        };

        this.load = this.load.bind(this);
    }

    render() {
        const { match, history } = this.props;
        const serviceProvider = this.props.serviceProviderResponse || {};

        return (
            <div className="ServiceProviderDetails">
                <Loading isLoading={this.props.isFetching || this.props.clients.isLoading} size="md">
                    <h1 className="clearfix">
                        {serviceProvider.name}

                        <Reload load={this.load} />

                        <ButtonToolbar className="float-right">
                            { JWT.hasRole('ROLE_ADMINISTRATOR') === false ? null :
                                <Button size="sm"
                                    onClick={() => history.push(`${match.url}/edit`)}>
                                    Edit
                                </Button>
                            }
                            { Response.getLink(serviceProvider, 'cancel-hold') === null ? null :
                                <Button onClick={() => this.setState({showHoldConfirmation: true})}
                                        size="sm"
                                        className="ml-2">
                                    Cancel Hold
                                </Button>
                            }
                            { Response.getLink(serviceProvider, 'hold') === null ? null :
                                <Button onClick={() => this.setState({showHoldConfirmation: true})}
                                        size="sm"
                                        className="ml-2">
                                    Hold
                                </Button>
                            }
                        </ButtonToolbar>

                        <ConfirmationDialog show={this.state.showHoldConfirmation}
                            hide={() => this.setState({showHoldConfirmation: false})}
                            onConfirm={this.handleHold} />
                    </h1>

                    <dl>
                        <dt>Exchequer Employee ID</dt>
                        <dd>{serviceProvider.exchequerEmployeeId}</dd>
                        <dt>Sage Supplier Reference</dt>
                        <dd>{serviceProvider.sageSupplierReference ? serviceProvider.sageSupplierReference : 'N/A'}</dd>
                        <dt>Status</dt>
                        <dd>{serviceProvider.isActive ? 'Active' : 'Inactive'}</dd>
                    </dl>

                    { this.listPartnerships() }
                </Loading>
            </div>
        );
    }

    handleHold = () => {
        let action;
        if (Response.getLink(this.props.serviceProviderResponse, 'hold')) {
            action = 'hold';
        } else {
            action = 'cancelHold';
        }

        this.setState({showHoldConfirmation: false});
        this.props[action](this.props.serviceProviderResponse.id);
    };

    listPartnerships()
    {
        const clients = this.props.clients.collection.clients.slice();
        const serviceProvider = this.props.serviceProviderResponse || {};
        let partnerships = serviceProvider.partnerships || [];

        let rows = [];
        while (clients.length >= 1) {
            rows.push(clients.splice(0, 3));
        }

        return (
            <>
                <h2>
                    Partnerships
                </h2>
                <Container fluid className="no-padding">
                    { rows.map((row, index) => {
                        return (
                            <Row sm={3} md={3} lg={3} xl={3} noGutters={true} key={index}>
                                { row.map((item) => {
                                    let predicate = {_links: {client: {href: item._links.self.href}}};
                                    let partnership = find(partnerships, predicate);
                                    return (
                                        <Col sm={4} className="bordered" key={item.id}>
                                            <div className="content">
                                                <h3>{ item.name }</h3>
                                                <dl>
                                                    <dt>Elogbooks User ID</dt>
                                                    <dd>{typeof partnership === 'undefined' ? '' : partnership.elogbooksUserId}</dd>
                                                </dl>
                                            </div>
                                        </Col>
                                    );
                                }) }
                            </Row>
                        );
                    }) }
                </Container>
            </>
        );
    }

    componentDidMount() {
        this.load();
        this.props.fetchAllClients({isActive: ['true']});
    }

    load() {
        const { id } = this.props.match.params;

        this.props.fetchServiceProvider(id);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderDetails);
